import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper, Box, Grid,Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import '@fontsource/orbitron/700.css';
import { useQuery } from '@apollo/client';
import { GET_DW_FIELDS } from '../../queries';
import DownloadIcon from '@mui/icons-material/Download';

const DetailView = ({ table }) => {
  // Preserve existing field data query
  const { data: fieldData, loading: fieldLoading, error: fieldError } = useQuery(GET_DW_FIELDS, {
    variables: { tableId: table.id },
  });

  // Keep existing DataGrid columns
  const columns = [
   
    { field: 'fieldName', headerName: 'Field Name', width: 150 },
    { field: 'dataType', headerName: 'Data Type', width: 150 },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'notes', headerName: 'Notes', width: 200 },
  ];

  // Maintain existing row processing
  const rows = fieldData?.fields.map((field) => ({
    id: field.id,
    fieldName: field.fieldName,
    dataType: field.dataType,
    description: field.description || 'N/A',
    notes: field.notes || 'N/A',
  }));

  return (
    <Box sx={{ p: 3 }}>
      {/* Title - keeping existing styling */}
      <Typography
        sx={{
          fontFamily: 'Orbitron, sans-serif',
          color: '#FDB813',
          fontStyle: 'italic',
          fontSize: '1.5rem',
          mb: 3
        }}
      >
        {table.displayName || table.tableName}
      </Typography>

      <Grid container spacing={3}>
        {/* Description Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Description</Typography>
            <Typography sx={{color:'silver'}} >{table.description || 'No description available'}</Typography>
          </Paper>
        </Grid>

        {/* Tips & Intended Use Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography  variant="h6" sx={{ mb: 2 }}>Tips, Intended Use, & AU</Typography>
            <Typography sx={{color:'silver'}}>
              {table.source ? `Source: ${table.source}` : 'No usage information available'}
            </Typography>
          </Paper>
        </Grid>

        {/* Fields Section with DataGrid */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Fields</Typography>
            {fieldLoading ? (
              <Typography>Loading fields...</Typography>
            ) : fieldError ? (
              <Typography>Error loading fields: {fieldError.message}</Typography>
            ) : (
              <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10, 20]}
                  disableSelectionOnClick
                />
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Update History and Raw Data Section */}
        <Grid item xs={12} md={4}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Update History</Typography>
                <Box sx={{ color: 'silver',
                  display: 'table', 
                  width: '100%',
                  '& .row': {
                    display: 'table-row',
                    '& > div': {
                      display: 'table-cell',
                      py: 1
                    }
                  }
                }}>
                  <Box className="row">
                    <Typography variant="subtitle2">Note</Typography>
                    <Typography variant="subtitle2">Date</Typography>
                  </Box>
                  <Box className="row">
                    <Typography sx={{color:'silver'}}>Initial setup</Typography>
                    <Typography sx={{color:'silver'}}>2024-01-20</Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Raw Data</Typography>
                <Button variant="contained" startIcon={<DownloadIcon />} >
                        Download Raw Data 
                    </Button>
                
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

DetailView.propTypes = {
  table: PropTypes.shape({
    id: PropTypes.number.isRequired,
    tableName: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    description: PropTypes.string,
    source: PropTypes.string,
    schema: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default DetailView;