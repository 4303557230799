import React, { useState, useMemo } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Alert, CircularProgress, Paper, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_DATASETS } from '../../queries';
import { enterpriseGridOverrideTheme } from '../../themes/EnterpriseGridTheme';
import { useNavigate } from 'react-router-dom';  

const DatasetsHome = () => {
    const navigate = useNavigate();  

    const { data: dsData, loading: dsLoading, error: dsError } = useQuery(GET_DATASETS);

    const rows = useMemo(() => {
        if (!dsData?.dataSets) return [];
        return dsData.dataSets.map((item, index) => ({
            id: index,
            ...item,
        }));
    }, [dsData]);

    const columns = useMemo(
        () => [
            { field: 'name', headerName: 'Name', width: 300 },
            { field: 'description', headerName: 'Description', width: 500 },
            {
                field: 'updatedOn',
                headerName: 'Updated On',
                width: 300,
                valueFormatter: (params) => {
                    if (!params) return "";
                    return new Date(params).toLocaleDateString(); // Formats to MM/DD/YYYY or locale-specific format
                }
            }
        ],
        []
    );

    // Handle loading state
    if (dsLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    // Handle error state
    if (dsError) {
        return (
            <Box sx={{ padding: 2 }}>
                <Alert severity="error">Error loading DataSets: {dsError.message}</Alert>
            </Box>
        );
    }

    // Updated row click handler to navigate to dataset detail page
    const handleRowClick = (params) => {
        navigate(`/datasets/${params.row.key}`); // Assuming each dataset has a 'key' field
    };


    return (
        <Paper elevation={3} sx={{ p: 2, maxWidth: '100%', mt: 4 }}>

            <Typography variant="h1" component="h1" gutterBottom align="left">
                Data Sets
            </Typography>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 25]}
                disableSelectionOnClick={false}
                onRowClick={handleRowClick}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                    },
                }}
                autoHeight
                headerHeight={40}
                sx={{
                    ...enterpriseGridOverrideTheme,
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        '&.Mui-selected': {
                            backgroundColor: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            },
                        },
                    },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    }
                }}
            />
        </Paper>
    );
};

export default DatasetsHome;
