
import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItemButton,
  ListSubheader,
  Divider,
  Typography,
} from '@mui/material';

const NavigationMenu = ({ tablesData, selectedTable, onTableSelect }) => {
  return (
    <List>
      {Object.keys(tablesData).map((section) => (
        <div key={section}>
          <ListSubheader component="div">
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4C85C5' }}>
              {section}
            </Typography>
          </ListSubheader>
          {tablesData[section].map((table) => (
            <ListItemButton
              key={table.id} // Use a unique identifier
              selected={selectedTable?.id === table.id} // Highlight if selected
              onClick={() => onTableSelect(table)}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: 'primary.light', // Highlight color
                  color: 'primary.contrastText', // Text color when selected
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'primary.main', // Slightly darker on hover
                },
                '&:hover': {
                  backgroundColor: 'action.hover', // Hover effect for unselected
                },
              }}
            >
              <div>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: selectedTable?.id === table.id ? 'bold' : 'normal',
                    color: selectedTable?.id === table.id ? '#FDB813' : 'text.primary',
                  }}
                >
                  {table.displayName}
                </Typography>
                {table.description && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: selectedTable?.id === table.id ? 'primary.contrastText' : 'text.secondary',
                      marginTop: '0px',
                    }}
                  >
                    {table.description}
                  </Typography>
                )}
              </div>
            </ListItemButton>
          ))}
          <Divider />
        </div>
      ))}
    </List>
  );
};

NavigationMenu.propTypes = {
  tablesData: PropTypes.object.isRequired, // e.g., { 'Data Warehouse': [...], 'Data Mart': [...] }
  selectedTable: PropTypes.object, // The selected table object
  onTableSelect: PropTypes.func.isRequired, // Function to handle table selection
};

export default NavigationMenu;
