import React from "react";
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
    Box,
    Alert,
    CircularProgress,
    Paper,
    Typography,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
} from '@mui/material';
import { GET_DATASET_BY_KEY } from '../../queries';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useAuthenticatedDownload from '../../hooks/useAuthenticatedDownload';
import config from '../../config';

const DatasetDetail = () => {
    // Adjust this based on your route; here we're destructuring 'datasetKey'
    const { key: key } = useParams();
    console.log(key);
    const { data: dsData, loading: dsLoading, error: dsError } = useQuery(GET_DATASET_BY_KEY, {
        variables: { key },
        fetchPolicy: 'network-only',
    });

    // Use your custom download hook
    const { downloadFile, isLoading, error: downloadError } = useAuthenticatedDownload();

    // Handler for authenticated download
    const handleDownload = async () => {
        const url = `${config.apiBaseUrl}/analysis/download/${key}`;
        const filename = `${key}.zip`;
        await downloadFile(url, filename);
      };

    const loadNotebook = (fileName) => {
        const fileUrl = `/notebooks/${fileName}`;
        window.open(fileUrl, "_blank");
    };

    if (dsLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (dsError) {
        return (
            <Box sx={{ padding: 2 }}>
                <Alert severity="error">Error loading DataSet: {dsError.message}</Alert>
            </Box>
        );
    }

    return (
        <Paper elevation={3} sx={{ p: 1, maxWidth: '100%', mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0 }}>
                <Typography sx={{ paddingLeft: 1 }} variant="h1" component="h1" gutterBottom align="left">
                    {dsData.dataSetByKey.name}
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownload}
                    disabled={isLoading}
                >
                    {isLoading ? "Downloading..." : "Download Data"}
                </Button>
            </Box>

            {downloadError && (
                <Box sx={{ mt: 2 }}>
                    <Alert severity="error">Download Error: {downloadError.message}</Alert>
                </Box>
            )}

            <Grid container spacing={3}>
                {/* Data Set Details Section */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={1} sx={{ backgroundColor: '#191919', p: 1, height: '100%' }}>
                        <Typography variant="h6" sx={{ color: '#1976d2', mb: 0 }}>
                            Data Set Details
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle2">Description:</Typography>
                            <Typography variant="body2" color="text.secondary">
                                {dsData.dataSetByKey.description}
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle2">Intended Audience:</Typography>
                            <Typography variant="body2" color="text.secondary">
                                {dsData.dataSetByKey.intendedAudience || 'N/A'}
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle2" component="span">Updated: </Typography>
                            <Typography variant="body2" color="text.secondary" component="span">
                                {dsData.dataSetByKey.updatedOn
                                    ? new Date(dsData.dataSetByKey.updatedOn).toLocaleDateString()
                                    : "N/A"}
                            </Typography>
                            <Typography variant="subtitle2" component="span" sx={{ ml: 2 }}>Version: </Typography>
                            <Typography variant="body2" color="text.secondary" component="span">
                                {dsData.dataSetByKey.version}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="subtitle2">Query:</Typography>
                            <Box
                                sx={{
                                    bgcolor: 'grey.100',
                                    p: 1,
                                    borderRadius: 1,
                                    maxHeight: '300px',
                                    overflow: 'auto'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'monospace',
                                        whiteSpace: 'pre-wrap',
                                        color: 'black',
                                        wordBreak: 'break-word'
                                    }}
                                >
                                    {dsData.dataSetByKey.query}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>

                {/* Data Dictionary Section */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={1} sx={{ backgroundColor: '#191919', p: 1, height: '100%' }}>
                        <Typography variant="h6" sx={{ color: '#1976d2', mb: 0 }}>
                            Notes
                        </Typography>
                        <Box
                            sx={{
                                color: 'text.secondary',
                                maxHeight: '500px',
                                overflow: 'auto',
                                '& a': { color: '#1976d2' },
                                '& table': { width: '100%' },
                                '& td, & th': { padding: '8px', borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }
                            }}
                        >
                            <ReactMarkdown>
                                {dsData.dataSetByKey.dataDictionary}
                            </ReactMarkdown>
                        </Box>
                    </Paper>
                </Grid>

                {/* Artifacts Section */}
                <Grid item xs={12}>
                    <Paper elevation={1} sx={{ backgroundColor: '#191919', p: 1 }}>
                        <Typography variant="h6" sx={{ color: '#1976d2', mb: 2 }}>
                            Artifacts
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Asset</TableCell>
                                        <TableCell>Link</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Purpose</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Raw Data</TableCell>
                                        <TableCell>
                                            <Button
                                                sx={{
                                                    backgroundColor: '#31af49',
                                                    '&:hover': {
                                                        backgroundColor: '#31af49',
                                                        boxShadow: 'none',
                                                        opacity: 1,
                                                    }
                                                }}
                                                variant="contained"
                                                size="small"
                                                startIcon={<DownloadIcon />}
                                                onClick={handleDownload}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? "Downloading..." : "Download CSV"}
                                            </Button>
                                        </TableCell>
                                        <TableCell>CSV File</TableCell>
                                        <TableCell>External processing</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Business Intelligence</TableCell>
                                        <TableCell>
                                            <Button
                                                sx={{
                                                    backgroundColor: '#31af49',
                                                    '&:hover': {
                                                        backgroundColor: '#31af49',
                                                        boxShadow: 'none',
                                                        opacity: 1,
                                                    }
                                                }}
                                                variant="contained"
                                                size="small"
                                                href={dsData.dataSetByKey.powerBILink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                startIcon={<ArrowForwardIcon />}
                                            >
                                                Open in PowerBI
                                            </Button>
                                        </TableCell>
                                        <TableCell>PowerBI</TableCell>
                                        <TableCell>Data exploration and export</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Data Analysis</TableCell>
                                        <TableCell>
                                            <Button
                                                sx={{
                                                    backgroundColor: '#31af49',
                                                    '&:hover': {
                                                        backgroundColor: '#31af49',
                                                        boxShadow: 'none',
                                                        opacity: 1,
                                                    }
                                                }}
                                                variant="contained"
                                                size="small"
                                                onClick={() => loadNotebook(dsData.dataSetByKey.notebookFileName)}
                                                startIcon={<ArrowForwardIcon />}
                                            >
                                                Open Notebook
                                            </Button>
                                        </TableCell>
                                        <TableCell>Python Notebook</TableCell>
                                        <TableCell>Visualization, in-depth analysis, prediction</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DatasetDetail;
