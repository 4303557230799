// src/components/DWExplorer/DataMartPage.js

import React, { useState, useMemo, useEffect, useCallback } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from "@mui/x-data-grid";


import { useNavigate, useSearchParams } from "react-router-dom";
//import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';
import NavigationMenu from "./NavigationMenu";
import { Box, Typography, List, ListItemButton, ListItemText, Divider, Alert, CircularProgress } from '@mui/material';
import DetailView from './DetailView';

import { GET_DW_TABLES } from '../../queries';
import { GET_FIELDS } from '../../queries';
import { GET_DATA_MART_TABLES } from '../../queries';

import { gql, useQuery } from '@apollo/client';


const DataMartPage = () => {
  const [selectedTable, setSelectedTable] = useState(null);

  // Fetch Data Mart Tables
  const { data: dataMartData, loading: loadingDataMart, error: errorDataMart } = useQuery(GET_DATA_MART_TABLES);

  // Fetch Data Warehouse Tables
  const { data: dwData, loading: loadingDW, error: errorDW } = useQuery(GET_DW_TABLES);

  // Combine tables data using useMemo
  const tablesData = useMemo(() => {
    const dataMartTables = dataMartData?.dataMartTables || [];
    const dwTables = dwData?.dwTables || [];

    return {
   
      'Data Mart': dataMartTables,
      'Data Warehouse': dwTables,
    };
  }, [dataMartData, dwData]);

  // Handle loading and error states
  if (loadingDataMart || loadingDW) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (errorDataMart || errorDW) {
    return (
      <Box sx={{ padding: 2 }}>
        {errorDataMart && <Alert severity="error">Error loading Data Mart Tables: {errorDataMart.message}</Alert>}
        {errorDW && <Alert severity="error">Error loading Data Warehouse Tables: {errorDW.message}</Alert>}
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Sidebar for DataMartPage */}
      <Box
        sx={{
          width: 240,
          borderRight: "1px solid #e0e0e0",
          paddingTop: 4,
          paddingLeft: 1,
          overflow: "auto", // Enable scrolling
          height: "100%", // Ensure it fills the parent container
        }}
      >

        <Typography sx={{
          color: '#FDB813'
        }} variant="h5" gutterBottom>
          Data Explorer
        </Typography>
        <NavigationMenu
          tablesData={tablesData}
          selectedTable={selectedTable}
          onTableSelect={setSelectedTable}
        />
      </Box>

      {/* Detail View */}
      <Box sx={{ flexGrow: 1, paddingTop: 4, paddingLeft: 1 }}>
        {selectedTable ? (
          <DetailView table={selectedTable}  />
        ) : (
          <Typography variant="h6" color="text.secondary">
            Please select a table from the Data Explorer menu.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DataMartPage;
